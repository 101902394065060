<template>
  <component
    :is="!noTeleport ? Teleport : 'div'"
    :to="!noTeleport ? 'body' : undefined"
  >
    <ZOverlay
      no-wrap
      :show="visible"
      fixed
      no-center
      :variant="variant"
      :class="[modalClass, { 'over-header': !underAppHeader }]"
      v-bind="$attrs"
      @click="overlayClick"
      @shown="emit('shown')"
    >
      <template #overlay>
        <ZCard
          no-body
          :class="[position, { shown }]"
          :style="withHeaderHeight"
        >
          <ZCardHeader v-if="!hideHeader">
            <slot name="header">
              <span
                v-if="title"
                class="title"
              >{{ title }}</span>
              <FormInputClearButton
                variant="dark"
                @click="close"
              />
            </slot>
          </ZCardHeader>
          <component :is="tag">
            <slot />
          </component>
          <ZCardFooter v-if="!hideFooter && hasFooterSlot">
            <slot name="footer" />
          </ZCardFooter>
        </ZCard>
      </template>
    </ZOverlay>
  </component>
</template>

<script setup lang="ts">
import { Teleport } from 'vue'
import { ZCardBody } from '#components'
import type { ComponentPosition, OverlayVariant } from '~/types/style-guide'

defineOptions({
  inheritAttrs: false,
})

type OverlayDrawerPosition = Extract<ComponentPosition, 'bottom' | 'top'>

const props = withDefaults(defineProps<{
  visible?: boolean
  hideHeader?: boolean
  hideFooter?: boolean
  title?: string
  position?: OverlayDrawerPosition
  preventControlScroll?: boolean
  noCardBody?: boolean
  underAppHeader?: boolean
  noAnimation?: boolean
  modalClass?: string
  noTeleport?: boolean
  variant?: OverlayVariant
}>(), {
  visible: false,
  hideHeader: false,
  hideFooter: false,
  title: '',
  position: 'bottom',
  preventControlScroll: false,
  noCardBody: false,
  underAppHeader: false,
  noAnimation: false,
  modalClass: '',
  noTeleport: false,
  variant: 'primary',
})

const emit = defineEmits<{
  close: []
  shown: []
}>()

const shown = ref(props.noAnimation ? props.visible : false)
const tag = computed(() => (props.noCardBody ? 'div' : ZCardBody))

const slots = useSlots()
const hasFooterSlot = computed(() => Boolean(slots.footer))
const withHeaderHeight = computed(() => {
  if (import.meta.server) {
    return
  }

  const appHeader = document.querySelector('.app-header') as HTMLDivElement
  const headerHeight = appHeader ? appHeader.offsetHeight : 0

  const appDownloadBanner = document.querySelector('.app-download-banner') as HTMLDivElement
  const appDownloadBannerHeight = appDownloadBanner ? appDownloadBanner.offsetHeight : 0

  const totalHeaderHeight = headerHeight + appDownloadBannerHeight

  return [
    { 'max-height': `calc(100dvh - ${totalHeaderHeight}px - 0.25rem)` },
    { 'max-height': `calc(100vh - ${totalHeaderHeight}px - 0.25rem)` },
    { top: shown.value && props.position === 'top' && props.underAppHeader ? `${totalHeaderHeight}px` : undefined },
  ]
})

const overlayClick = (e: Event) => {
  if (((e?.target as HTMLDivElement)?.parentNode as HTMLDivElement | HTMLBodyElement)?.classList.contains('zoverlay')) {
    close()
  }
}

const close = () => {
  shown.value = false

  setTimeout(() => {
    emit('close')
  }, 150)
}

// Control scroll
const controlScroll = (show: boolean) => {
  if (!import.meta.server) {
    const bodyClasses = document.body.classList
    if (show) {
      if (!bodyClasses.contains('noscroll') && !props.preventControlScroll) {
        bodyClasses.add('noscroll')
      }

      setTimeout(() => {
        shown.value = true
      }, 150)
    }
    else if (!props.preventControlScroll) {
      bodyClasses.remove('noscroll')
    }
  }
}

onMounted(() => {
  if (props.noAnimation) {
    controlScroll(props.visible)
  }
})

onBeforeUnmount(() => {
  controlScroll(false)
})

watch(
  () => props.visible,
  (newValue) => controlScroll(newValue),
  {
    immediate: true,
  },
)
</script>

<style lang="scss" scoped>
.zoverlay.over-header {
  z-index: $headerZindex + 1 !important;
}

.zcard {
  position: fixed;
  left: 0;
  right: 0;
  max-height: 100vh;
  max-height: 100dvh;
  overflow: hidden;

  &.bottom {
    border-radius: 1rem 1rem 0 0;
    bottom: -100%;
    transition: bottom ease 250ms;

    &.shown {
      bottom: 0;
      transition: bottom ease 250ms;
    }

    > :first-child {
      border-radius: 1rem 1rem 0 0;
    }
  }

  &.top {
    border-radius: 0 0 1rem 1rem;
    top: -100%;
    transition: top ease 250ms;

    &.shown {
      top: 0;
      transition: top ease 250ms;
    }

    > :last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }

  .zcard-header {
    background-color: inherit;
    border-color: transparent;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    .title {
      @include strong-1;
      color: getColor('highlight-800');
    }

    .close {
      @include strong-1;
      width: 1.5rem;
      height: 1.5rem;
      padding: 0;
      border-radius: 50%;
      background-color: getColor('primary-50');
      text-shadow: none;
      color: getColor('primary-500');
      opacity: 1;
    }
  }

  .zcard-body {
    padding: 1.5rem 1rem;
    overflow-y: auto;
    overflow-x: hidden;

    :deep(.calendars-container) {
      max-width: initial;
    }
  }

  .zcard-footer {
    background: #fff;
    display: flex;
    border-color: getColor('primary-100');
    justify-content: space-between;
    align-items: center;
  }
}
</style>
